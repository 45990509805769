<template>
  <v-container class="mt-0 pt-0 mb-12 pb-12">
    <TimePanel
      :actualInterval="actualInterval"
      :syncingInterval="syncingInterval"
      :vehiclePlate="vehiclePlate"
    />

    <v-container
      class="pl-0 pr-0"
      v-if="(journeyStarted || geolocation) && !syncing"
    >
      <v-card-text
        style="height: 100px; position: relative"
        class="d-flex justify-center"
      >
        <v-fab-transition>
          <v-btn
            :color="journeyStarted ? 'error' : 'success'"
            :title="journeyStarted ? 'Finalizar Jornada' : 'Iniciar Jornada'"
            dark
            height="100"
            width="100"
            absolute
            x-large
            fab
            @click="journeyStarted ? finishJourney() : startJourney()"
          >
            <v-icon large>{{
              getIcon(journeyStarted ? 'FINAL_JORNADA' : 'INICIO_JORNADA')
            }}</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-card-title class="mt-16 pt-10">{{
          journeyStarted ? 'Finalizar Jornada' : 'Iniciar Jornada'
        }}</v-card-title>
      </v-card-text>

      <v-row class="d-flex align-start mt-12" v-if="journeyStarted">
        <v-col
          style="height:140px"
          v-for="option in intervalOptions"
          :key="option.const"
          :class="getColOptionClass(option)"
          class="d-flex flex-column align-center justify-start"
          xl="2"
          lg="2"
          md="2"
          sm="2"
          cols="4"
        >
          <v-btn
            x-large
            height="90"
            width="90"
            :color="getButtonColor(option)"
            :title="option.title"
            :disabled="!journeyStarted"
            @click="setIntervalJourney(option)"
          >
            <v-icon :color="getIconColor(option.const)" x-large>{{
              getIcon(option.const)
            }}</v-icon>
          </v-btn>
          <span class="text-center mt-1 caption font-weight-medium">
            {{ option.title.toUpperCase() }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text v-if="!geolocation && !syncing && !journeyStarted"
      ><v-card-title class="justify-center">
        Para utilizar o sistema é necessário ativar a localização GPS
      </v-card-title></v-card-text
    >

    <!-- Dialog para digitar a descrição do intervalo "Outros" -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card
        ><v-card-title>{{
          alertType === 'OTHERS'
            ? 'Outros'
            : 'Selecione o veículo a ser dirigido'
        }}</v-card-title>

        <v-card-text v-if="alertType === 'OTHERS'">
          <v-row>
            <v-col cols="9" sm="9" md="9" lg="9" xl="9">
              <v-text-field
                label="Descrição"
                v-model="othersDescription"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" xl="3" class="mt-4">
              <v-btn color="success" class="mb-2" @click="setOthersDescription"
                ><v-icon> {{ mdiCheck }}</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                <v-autocomplete
                  v-model="vehicle"
                  item-value="id"
                  item-text="plate"
                  dense
                  label="Veículo"
                  :items="vehicles"
                  :clearable="clearable"
                  :rules="rules.vehicle"
                  @focus="clearable = true"
                  @blur="clearable = false"
                >
                  <template v-slot:append>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon v-if="vehicle" @click="vehicle = null">{{
                        mdiClose
                      }}</v-icon>
                    </v-slide-x-reverse-transition>
                  </template></v-autocomplete
                >
              </v-col>
              <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                <v-btn color="success" @click="setDirectionVehicle"
                  ><v-icon> {{ mdiCheck }}</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- data atual -->
    <div class="mt-16 d-flex flex-row align-center">
      <span class="title">
        {{ new Date().toLocaleDateString() }}
      </span>
      <span class="font-weight-light ml-1">
        | Histórico
      </span>
      <span class="ml-auto font-weight-thin subtitle-2">
        Duração
      </span>
    </div>

    <!-- historico -->
    <v-timeline dense v-if="reverseHistoric.length">
      <v-timeline-item
        large
        v-for="item in reverseHistoric"
        :key="item.idx"
        :color="getColor(item.state)"
      >
        <div class="d-flex flex-row justify-space-between">
          <span class="title font-weight-light">
            {{ dataHoraTimeline(item) }}
          </span>
          <span class="title font-weight-thin">
            {{ item.fim ? intervalDuration(item) : '00:00:00' }}
          </span>
        </div>
        <span class="d-block">
          {{ item.descricao }}
        </span>
        <v-icon
          :dark="
            !['INTERVALO_REFEICAO', 'INTERVALO_JORNADA'].includes(item.state)
          "
          :color="getIconColor(item.state)"
          slot="icon"
        >
          {{ getIcon(item.state) }}
        </v-icon>
      </v-timeline-item>
    </v-timeline>
    <div style="height:100px">&nbsp;</div>
    <v-snackbar v-model="alert" :color="alertColor" top :timeout="3000">
      {{ alertText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  mdiClose,
  mdiCheck,
  mdiClockStart,
  mdiSteering,
  mdiAlert,
  mdiPasta,
  mdiTimerOutline,
  mdiTruckCheck,
  mdiWeight,
  mdiPoliceBadge,
  mdiTrafficLight,
  mdiCar,
  mdiGasStation,
  mdiTools,
  mdiTire,
  mdiDotsHorizontal,
  mdiBed,
  mdiTruckPlus,
  mdiTruckMinus,
  mdiCarEmergency
} from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import { VehiclesService } from '@/services'
import { GeolocationService } from '@/services'
import { RealTimeService } from '@/services'
import {
  MODULE_NAME as CONFIG,
  GET_BOTAOREDONDO,
  GET_MODOESCURO
} from '@/store/config'
import {
  MODULE_NAME as AUTH,
  GET_USUARIO_MATRICULA,
  GET_USUARIO_NOME,
  GET_USUARIO_EMAIL
} from '@/store/auth'
import {
  MODULE_NAME as MODULE_VEHICLE,
  VEHICLES,
  VEHICLE,
  SET_VEHICLE,
  KEY_STORE
} from '../store/vehicles/constants'

import {
  MODULE_NAME as JORNADA,
  STATES,
  LOAD,
  INIT,
  STOP,
  GET_STATE,
  GET_TEMPO,
  GET_TIME_LOCALE,
  GET_JORNADA_INICIADA,
  GET_HISTORICO_REVERSO,
  SET_INICIO_JORNADA,
  SET_DIRECAO,
  SET_ESPERA,
  SET_INTERVALO_REFEICAO,
  SET_INTERVALO_JORNADA,
  SET_FINAL_JORNADA,
  SET_CARREGAMENTO,
  SET_DESCARREGAMENTO,
  SET_BALANCA,
  SET_FISCALIZACAO,
  SET_TRANSITO,
  SET_ACIDENTE,
  SET_ABASTECIMENTO,
  SET_MANUTENCAO,
  SET_TROCA_PNEU,
  SET_OUTROS,
  SET_DESCANSO,
  SET_PARADA_EMERGENCIAL,
  SET_DESCRIPTION
} from '@/store/jornada'

import {
  MODULE_NAME as DRIVING_TIME,
  RESTART_DRIVING_TIME,
  SET_DRIVING_TIME
} from '@/store/drivingTimeControl'
import {
  MODULE_NAME as LUNCH_TIME,
  RESET_LUNCH_TIME
} from '@/store/lunchTimeControl'
import {
  MODULE_NAME as TIME_CONTROL_MODULE,
  SET_TIME_CONTROL,
  GET_TIME_CONTROL,
  RESET_TIME_CONTROL
} from '@/store/timeControl'

import { TimePanel } from '../components'

const {
  INICIO_JORNADA,
  DIRECAO,
  ESPERA,
  INTERVALO_REFEICAO,
  INTERVALO_JORNADA,
  FINAL_JORNADA,
  CARREGAMENTO,
  DESCARREGAMENTO,
  BALANCA,
  FISCALIZACAO,
  TRANSITO,
  ACIDENTE,
  ABASTECIMENTO,
  MANUTENCAO,
  TROCA_PNEU,
  OUTROS,
  DESCANSO,
  PARADA_EMERGENCIAL
} = STATES

const COR = {
  [INICIO_JORNADA]: 'green',
  [DIRECAO]: 'green',
  [ESPERA]: 'grey lighten-4',
  [INTERVALO_JORNADA]: 'grey lighten-4',
  [INTERVALO_REFEICAO]: 'grey lighten-4',
  [FINAL_JORNADA]: 'red darken-1',
  [DESCARREGAMENTO]: 'grey lighten-4',
  [CARREGAMENTO]: 'grey lighten-4',
  [BALANCA]: 'grey lighten-4',
  [FISCALIZACAO]: 'grey lighten-4',
  [TRANSITO]: 'grey lighten-4',
  [ACIDENTE]: 'grey lighten-4',
  [ABASTECIMENTO]: 'grey lighten-4',
  [MANUTENCAO]: 'grey lighten-4',
  [TROCA_PNEU]: 'grey lighten-4',
  [OUTROS]: 'grey lighten-4',
  [DESCANSO]: 'grey lighten-4',
  [PARADA_EMERGENCIAL]: 'grey lighten-4'
}

const COR_ICON = {
  [INICIO_JORNADA]: 'white',
  [DIRECAO]: 'black',
  [ESPERA]: 'black',
  [INTERVALO_REFEICAO]: 'black',
  [INTERVALO_JORNADA]: 'black',
  [FINAL_JORNADA]: 'black',
  [CARREGAMENTO]: 'black',
  [DESCARREGAMENTO]: 'black',
  [BALANCA]: 'black',
  [FISCALIZACAO]: 'black',
  [TRANSITO]: 'black',
  [ACIDENTE]: 'black',
  [ABASTECIMENTO]: 'black',
  [MANUTENCAO]: 'black',
  [TROCA_PNEU]: 'black',
  [OUTROS]: 'black',
  [DESCANSO]: 'black',
  [PARADA_EMERGENCIAL]: 'black'
}

const ICON = {
  [INICIO_JORNADA]: mdiClockStart,
  [DIRECAO]: mdiSteering,
  [ESPERA]: mdiAlert,
  [INTERVALO_REFEICAO]: mdiPasta,
  [INTERVALO_JORNADA]: mdiTimerOutline,
  [FINAL_JORNADA]: mdiTruckCheck,
  [CARREGAMENTO]: mdiTruckPlus,
  [DESCARREGAMENTO]: mdiTruckMinus,
  [BALANCA]: mdiWeight,
  [FISCALIZACAO]: mdiPoliceBadge,
  [TRANSITO]: mdiTrafficLight,
  [ACIDENTE]: mdiCar,
  [ABASTECIMENTO]: mdiGasStation,
  [MANUTENCAO]: mdiTools,
  [TROCA_PNEU]: mdiTire,
  [OUTROS]: mdiDotsHorizontal,
  [DESCANSO]: mdiBed,
  [PARADA_EMERGENCIAL]: mdiCarEmergency
}

export default {
  name: 'InicioView',
  components: { TimePanel },
  data() {
    return {
      intervalOptions: [
        {
          const: DIRECAO,
          title: 'Direção',
          click: this.startDirection
        },
        {
          const: INTERVALO_REFEICAO,
          title: 'Refeição',
          click: this.intervaloRefeicao
        },
        {
          const: INTERVALO_JORNADA,
          title: 'Intervalo',
          click: this.intervaloJornada
        },

        {
          const: BALANCA,
          title: 'Balança',
          click: this.intervaloBalanca
        },
        {
          const: FISCALIZACAO,
          title: 'Fiscalização',
          click: this.intervaloFiscalizacao
        },
        {
          const: TRANSITO,
          title: 'Trânsito',
          click: this.intervaloTransito
        },
        {
          const: ACIDENTE,
          title: 'Acidente',
          click: this.intervaloAcidente
        },
        {
          const: ABASTECIMENTO,
          title: 'Abastecimento',
          click: this.intervaloAbastecimento
        },
        {
          const: MANUTENCAO,
          title: 'Manutenção',
          click: this.intervaloManutencao
        },
        {
          const: TROCA_PNEU,
          title: 'Troca de Pneu',
          click: this.intervaloTrocaPneu
        },
        {
          const: DESCANSO,
          title: 'Descanso',
          click: this.intervaloDescanso
        },
        {
          const: PARADA_EMERGENCIAL,
          title: 'Parada Emergencial',
          click: this.intervaloParadaEmergencial
        },
        {
          const: CARREGAMENTO,
          title: 'Carga',
          click: this.intervaloCarregamento
        },
        {
          const: DESCARREGAMENTO,
          title: 'Descarga',
          click: this.intervaloDescarregamento
        },
        {
          const: ESPERA,
          title: 'Espera',
          click: this.espera
        },
        {
          const: OUTROS,
          title: 'Outros',
          click: this.setOutrosIntervalo
        }
      ],
      rules: {
        vehicle: [v => !!v || 'Campo Obrigatório!']
      },
      clearable: false,
      mdiCheck,
      mdiClose,
      valid: false,
      vehicle: null,
      vehiclePlate: null,
      dialog: false,
      othersDescription: null,
      syncing: false,
      syncingInterval: false,
      isDirection: false,
      alert: false,
      alertText: null,
      alertColor: null,
      alertType: null,
      geolocation: true,
      verifyGeolocationInterval: null,
      geolocationPermissionInterval: null,
      normalWasSent: false,
      extraWasSent: false,
      message: ''
    }
  },
  watch: {
    async getTime(newValue, oldValue) {
      if (!this.journeyStarted) {
        this.resetTimeControl()
        this.restartDrivingTime()
      } else {
        if (new Date(newValue).getTime() !== new Date(oldValue).getTime()) {
          this.message = await this.setTimeControl(new Date(newValue))
          this.setDrivingTime()
          if (!this.normalWasSent) {
            if (this.message === 'normal') {
              this.alert = true
              this.alertColor = 'error'
              this.alertText = 'TEMPO DE JORNADA EXCEDIDO: 8 horas'
              this.normalWasSent = true
            }
          }
          if (!this.extraWasSent) {
            if (this.message === 'extra') {
              this.alert = true
              this.alertColor = 'error'
              this.alertText = 'TEMPO DE JORNADA EXCEDIDO: 10 horas'
              this.wasSent = true
              this.extraWasSent = true
            }
          }
        }
      }
    },
    async journeyStarted(newValue) {
      if (newValue !== null && newValue !== undefined && newValue) {
        this.$vuetify.goTo(350, {
          duration: 1000,
          offset: 0,
          easing: 'linear'
        })
      }
    },
    state(newValue, oldValue) {
      this.syncingInterval = true
      if (newValue !== DIRECAO) {
        this.isDirection = false
      }
      if (oldValue !== 'NAO_INICIADO') {
        if (navigator.onLine) {
          const RealTime = RealTimeService(this.$store)
          RealTime.synchronize(this.reverseHistoric[0], this.userRegistration)
        }
      }
      this.syncingInterval = false
    },
  },
  computed: {
    ...mapGetters(TIME_CONTROL_MODULE, {
      getTimeControl: GET_TIME_CONTROL
    }),
    ...mapGetters(CONFIG, [GET_BOTAOREDONDO]),
    ...mapGetters(AUTH, {
      userRegistration: GET_USUARIO_MATRICULA,
      userName: GET_USUARIO_NOME,
      userEmail: GET_USUARIO_EMAIL
    }),
    ...mapGetters(JORNADA, {
      state: GET_STATE,
      getTime: GET_TEMPO,
      getCurrentTimeLocale: GET_TIME_LOCALE,
      journeyStarted: GET_JORNADA_INICIADA,
      reverseHistoric: GET_HISTORICO_REVERSO
    }),
    ...mapGetters(MODULE_VEHICLE, {
      vehicles: VEHICLES,
      vehicleSelected: VEHICLE
    }),
    darkMode() {
      return this.$store.getters[`${CONFIG}/${GET_MODOESCURO}`]
    },
    currentTime() {
      return this.getCurrentTimeLocale
    },
    actualInterval() {
      return this.reverseHistoric[0]
    }
  },
  methods: {
    ...mapActions(LUNCH_TIME, { resetLunchTime: RESET_LUNCH_TIME }),
    ...mapActions(DRIVING_TIME, {
      restartDrivingTime: RESTART_DRIVING_TIME,
      setDrivingTime: SET_DRIVING_TIME
    }),
    ...mapActions(TIME_CONTROL_MODULE, {
      setTimeControl: SET_TIME_CONTROL,
      resetTimeControl: RESET_TIME_CONTROL
    }),
    ...mapActions(JORNADA, {
      LOAD,
      INIT,
      STOP,
      inicioJornada: SET_INICIO_JORNADA,
      direcao: SET_DIRECAO,
      espera: SET_ESPERA,
      intervaloCarregamento: SET_CARREGAMENTO,
      intervaloDescarregamento: SET_DESCARREGAMENTO,
      intervaloBalanca: SET_BALANCA,
      intervaloFiscalizacao: SET_FISCALIZACAO,
      intervaloTransito: SET_TRANSITO,
      intervaloAcidente: SET_ACIDENTE,
      intervaloAbastecimento: SET_ABASTECIMENTO,
      intervaloManutencao: SET_MANUTENCAO,
      intervaloTrocaPneu: SET_TROCA_PNEU,
      intervaloOutros: SET_OUTROS,
      intervaloDescanso: SET_DESCANSO,
      intervaloParadaEmergencial: SET_PARADA_EMERGENCIAL,
      intervaloRefeicao: SET_INTERVALO_REFEICAO,
      intervaloJornada: SET_INTERVALO_JORNADA,
      finalJornada: SET_FINAL_JORNADA,
      setDescription: SET_DESCRIPTION
    }),
    ...mapActions(MODULE_VEHICLE, { setVehicle: SET_VEHICLE }),
    intervalDuration(item) {
      return new Date(
        0,
        0,
        0,
        0,
        0,
        0,
        item.fim - item.inicio
      ).toLocaleTimeString()
    },
    setIntervalJourney(option) {
      if (!this.em(option.const) || option.const === DIRECAO) {
        option.click()
      } else if (option.const === INTERVALO_REFEICAO) {
        this.setDescription('Atividade não informada')
        this.intervaloOutros()
      }
    },
    finishJourney() {
      this.resetTimeControl()
      this.resetLunchTime()
      this.finalJornada()
      this.restartDrivingTime()
    },
    startJourney() {
      this.$forceUpdate()
      if (!this.geolocation) {
        this.alert = true
        this.alertColor = 'error'
        this.alertText = 'Ative a localização!'
      }
      this.inicioJornada()
    },
    startDirection() {
      this.alertType = 'DIRECTION'
      this.dialog = true
    },
    setDirectionVehicle() {
      if (!this.valid) {
        this.$refs.form.validate()
        return
      }
      this.setVehicle(this.vehicle)
      this.vehiclePlate = this.vehicles.find(
        vehicle => vehicle.id === this.vehicle
      ).plate
      this.isDirection = true
      this.dialog = false
      this.direcao()
    },
    setOthersDescription() {
      this.setDescription(this.othersDescription)
      this.intervaloOutros()
      this.dialog = false
    },
    setOutrosIntervalo() {
      this.alertType = 'OTHERS'
      this.dialog = true
    },
    getColor(_state) {
      let color = 'primary'
      if (COR[_state]) {
        color = COR[_state]
      }
      return color
    },
    getIconColor(_state) {
      let color = 'white'
      if (COR_ICON[_state]) {
        color = COR_ICON[_state]
      }
      return color
    },
    getIcon(_state) {
      let icon = 'mdi-map-marker-radius'
      if (ICON[_state]) {
        icon = ICON[_state]
      }
      return icon
    },
    dataHoraTimeline(item) {
      if (item.state === FINAL_JORNADA) {
        return new Date(item.fim).toLocaleTimeString()
      }
      return new Date(item.inicio).toLocaleTimeString()
    },
    em(_state) {
      if (_state === FINAL_JORNADA) {
        return
      }
      return this.state === _state
    },
    getColOptionClass(option) {
      return {
        ' rounded-lg': this.em(option.const),
        grey: this.em(option.const) && this.darkMode,
        'blue lighten-5': this.em(option.const) && !this.darkMode
      }
    },
    getButtonColor(option) {
      const emState = this.em(option.const)
      let color = ''
      if (emState) {
        color = 'light-blue lighten-1'
      }
      if (this.darkMode && !emState) {
        color = 'cyan lighten-1'
      } else if (!this.darkMode && !emState) {
        color = 'grey lighten-4'
      }
      return color || this.getColor(option.const)
    },
    verfifyGeolocationPermission() {
      this.geolocationPermissionInterval = setInterval(async () => {
        if (navigator.onLine) {
          navigator.permissions
            .query({ name: 'geolocation' })
            .then(async result => {
              if (['prompt', 'denied'].includes(result.state)) {
                this.geolocation = false
              } else {
                this.geolocation = true
              }
            })
        } else {
          this.geolocation = true
        }
      }, 2500)
    }
  },
  async created() {
    await this[LOAD]()
    await this[INIT]()

    try {
      if (navigator.onLine) {
        const vehicleService = VehiclesService(this.$store)
        await vehicleService.getVehicles()
      }
    } catch (error) {
      console.error(error)
    }
    await GeolocationService.getLocation()

    const notSyncedIntervals = [
      ...this.reverseHistoric
        .filter(i => !Object.keys(i).includes('synced'))
        .reverse()
    ]

    console.log('Reverse historic', this.reverseHistoric)
    console.log(notSyncedIntervals)

    if (this.journeyStarted && notSyncedIntervals.length) {
      const RealTime = RealTimeService(this.$store)
      RealTime.synchronize(notSyncedIntervals, this.userRegistration)
    }
  },
  async mounted() {
    this.syncing = true
    setTimeout(() => {
      this.syncing = false
    }, 1000)

    this.vehicle = Number(localStorage.getItem(KEY_STORE))
    if (this.vehicle) {
      this.vehiclePlate = this.vehicles.find(
        vehicle => vehicle.id === this.vehicle
      ).plate
    }
    clearInterval(this.verfifyGeolocationPermission)
    this.verfifyGeolocationPermission()
  },
  async beforeDestroy() {
    if (this.journeyStarted) {
      await this[STOP]()
    }
  }
}
</script>
